<template>
  <div class="login-background">
    <div class="h-10per">
      <img class="logo" src="@/assets/images/logo.png" />
    </div>

    <div class="wrap bg-white h-90per w-100per">
      <div class="mg-auto pt-20" style="width: 1250px;">
        <h2 class="mt-00 font-18 bold"><span>■</span> 상담사 등록요청</h2>

        <div class="con_top">
          <input type="hidden" name="_token" id="_token" value="iu9xcVuFZ79uA3zQNaVTassFPxCio38exMLm1RxR" />
          <input type="hidden" name="center_id" id="center_id" value="1" />
          <input type="hidden" name="role_id" id="role_id" value="1" /><!-- 상담사 -->
          <input type="hidden" name="callback" id="callback" value="" />

          <div class="con">
            <div class="con_table">
              <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                <tbody>
                  <tr>
                    <th style="width:111px;">아이디 <span class="red">*</span></th>
                    <td class="left">
                      <input type="text" v-model="id" class="ml-20" style="width:200px;" id="id" name="id" value="" />
                      <button type="button" class="default-btn ml-20" @click="checkID()">중복체크</button><br />
                      <span class="ml-20"> 영문 대/소문자 + 숫자포함 6~20자</span>
                    </td>
                    <th>암호 <span class="red">*</span></th>
                    <td class="left">
                      <input type="text" v-model="pwd" class="ml-20" style="width:200px;" id="pwd" name="pwd" value="" /> <br />
                      <span class="ml-20"> 영문 대/소문자 + 숫자 + 특수문자 포함 6~20자</span>
                    </td>
                  </tr>
                  <tr>
                    <th>성명 <span class="red">*</span></th>
                    <td class="left">
                      <input type="text" v-model="name" class="ml-20" style="width:200px;" id="name" name="name" value="" />
                    </td>
                    <th>성별 <span class="red">*</span></th>
                    <td class="left">
                      <select v-model="gender" class="ml-20 w-60px">
                        <option value="">성별</option>
                        <option value="남자">남</option>
                        <option value="여자">여</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰 번호 <span class="red">*</span></th>
                    <td class="left">
                      <select class="ml-20" v-model="phone1" id="phone1" name="phone1">
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="017">017</option>
                        <option value="018">018</option>
                        <option value="019">019</option>
                      </select>
                      - <input type="text" style="width:100px;" v-model="phone2" id="phone2" name="phone2" value="" /> -
                      <input type="text" style="width:100px;" v-model="phone3" id="phone3" name="phone3" value="" />
                    </td>
                    <th>이메일 <span class="red">*</span></th>
                    <td class="left">
                      <input type="text" style="width:120px;" v-model="email1" class="ml-20" name="email1" id="email1" value="" />@
                      <input type="text" style="width:80px;" v-model="email2" class="mg5" id="email2" name="email2" value="" />
                      <select v-model="email3" name="email3" id="email3" @change="email2 = email3">
                        <option value="">직접선택</option>
                        <option value="gmail.com">gmail.com</option>
                        <option value="naver.com">naver.com</option>
                        <option value="nate.com">nate.com</option>
                        <option value="nate.com">daum.net</option>
                        <option value="etc" selected="selected">직접입력</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>생년월일 <span class="red">*</span></th>
                    <td class="left">
                      <select name="birth-yyyy" v-model="birthYY" class="ml-20" id="birth-yyyy">
                        <option v-for="year in parseYear()" :key="year" :value="year">{{ year }}년</option>
                      </select>
                      <select name="birth-mm" v-model="birthMM" class="ml-10" id="birth-mm">
                        <option v-for="index in 12" :key="index" :value="index">{{ index }}월</option>
                      </select>
                      <select name="birth-dd" v-model="birthDD" class="ml-10" id="birth-dd">
                        <option v-for="index in 31" :key="index" :value="index">{{ index }}일</option>
                      </select>
                    </td>
                    <th>최종학력 <span class="red">*</span></th>
                    <td class="left">
                      <select name="birth-dd" v-model="education" class="ml-20" id="birth-dd">
                        <option value="중학교">중졸</option>
                        <option value="고등학교">고졸</option>
                        <option value="대학졸업(2,3년)">대졸(2~3년)</option>
                        <option value="대학졸업(4년)">대졸(4년)</option>
                        <option value="석사졸업">석사</option>
                        <option value="박사졸업">박사</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>경력 <span class="red">*</span></th>
                    <td class="left">
                      <select name="working-day" v-model="career" class="ml-20" id="working-day">
                        <option v-for="index in 40" :key="index" :value="index">{{ index }}년</option>
                        <option :value="41">40년이상</option>
                      </select>
                    </td>
                    <th>자격증 <span class="red">*</span></th>
                    <td class="left">
                      <input type="hidden" id="idx_file_cer" name="idx_file_cer" value="0" />
                      <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.certFile.click()">파일 선택</button>
                      <input v-show="false" ref="certFile" type="file" @change="uploadCert($event)" /><br />
                      <span v-for="(item, index) of certFiles" :key="index" class="ml-20" style="cursor:pointer" @click="detailFile()">{{ item.name }}<br /></span>
                    </td>
                  </tr>
                  <tr>
                    <th>한줄소개 <span class="red">*</span></th>
                    <td class="left" colspan="12">
                      <input type="text" v-model="introduce" class="ml-20 w-98per" id="introduce" name="introduce" value="" />
                    </td>
                  </tr>
                  <tr>
                    <th>사진 <span class="red">*</span></th>
                    <td class="left" colspan="12">
                      <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.profileFile.click()">파일 선택</button>
                      <input v-show="false" ref="profileFile" type="file" @change="uploadProfile($event)" /><br />
                      <span v-if="profileFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{ profileFile.name }}</span>
                    </td>
                  </tr>

                  <tr>
                    <th>센터 <span class="red">*</span></th>
                    <td class="left" colspan="12">
                      <input type="text" list="centerlist" id="center" class="ml-20 w-400px center_text" v-model="centerName" />
                      <datalist id="centerlist" v-if="centerList.length > 0 && centerName.length > 0">
                        <option v-for="(item, index) in centerList" :key="'centerfilter' + index">
                          {{ item.name }}
                        </option>
                      </datalist>
                    </td>
                  </tr>

                  <tr>
                    <th>상담유형<span class="red">*</span></th>
                    <td class="left" colspan="12">
                      <select v-model="idxCrmMetaType" class="w-200px  ml-20">
                        <option value="-1">상담유형</option>
                        <option v-for="(item, index) of typeList" :key="index" :value="item.idx" v-show="index >= 7">
                          {{ item.type }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="btns3 mgB80">
                <a class="btn_sms mr-10 pointer" @click="moveLogin()">취소</a>
                <a class="btn_cancle pointer" @click="postUserInfo()">등록요청</a>
              </div>
              <p class="txt-red mt-10">* 등록요청 하신 후 관리자가 승인하면 CRM이용이 가능합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IDFormatCheck, PWFormatCheck, EmptyCheck } from "@/utils/validation.js";
export default {
  data: () => ({
    id: "", // 아이디
    pwd: "", // 비밀번호,
    name: "", // 이름
    gender: "",
    phone1: "010",
    phone2: "",
    phone3: "",
    email1: "",
    email2: "",
    email3: "",
    birthYY: 2020, // 생년월일 YYYY
    birthMM: 1, // 생년월일 MM
    birthDD: 1, // 생년월일 DD
    birthTempMM: 1, // 생년월일 MM
    birthTempDD: 1, // 생년월일 DD
    education: "중학교",
    career: 1,
    introduce: "",

    certFiles: [], // 자격증 파일 리스트 (최대 5개)
    profileFile: "", // 프로필 사진 파일
    autoSearchCenter: "", // 센터 자동완성 검색
    isAutoCenter: false, // 센터 자동완성 on/off
    centerName: "",
    centerFilterList: [],
    center: "", // 센터명
    centerList: [], // 센터명 리스트 (자동완성)
    idxCrmCenter: -1,
    idxCrmMetaType: -1,
    typeList: [],
    idxCrmMetaPro: -1,
    isValidID: false, // id 중복 여부 false 일때 중복체크 안된상태
    btnDisabled: false, // 등록요청시 버튼 이벤트 정지
  }),

  mounted() {
    this.getCenterListAll();
    this.getMetaList();
  },

  watch: {
    centerName(newCenterName) {
      if (newCenterName) {
        this.setIdxCrmCenter();
      }
    },
  },

  methods: {
    checkID() {
      // 아이디 검사
      if (!EmptyCheck(this.id, "아이디를")) {
        return false;
      } else {
        if (!IDFormatCheck(this.id)) {
          return false;
        }
      }

      var params = {
        id: this.id,
      };
      this.axios
        .get("/api/v1/user/dup", {
          params: params,
        })
        .then((res) => {
          if (res.data.err === 0) {
            alert("사용 가능한 아이디입니다.");
            this.isValidID = true;
          } else {
            alert("이미 사용중인 아이디입니다.");
          }
        })
        .catch((err) => {
          alert(err);
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    // 유입경로, 상담구분, 상담유형 리스트
    getMetaList() {
      this.axios.get("/api/v1/client/meta", {}).then((res) => {
        if (res.data.err === 0) {
          this.typeList = res.data.typeList;
        }
      });
    },

    getCenterListAll() {
      this.axios
        .get("/api/v1/center/all", {})
        .then((res) => {
          if (res.data.err === 0) {
            this.centerList = res.data.centerList;
          } else {
            alert("센터리스트 결과가 없습니다.");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    // 현재 년도에 따라서 120년만큼 selectbox
    parseYear() {
      var nowYear = new Date().getFullYear();
      var parseYear = [];
      for (var i = 0; i <= 120; i++) {
        parseYear.push(nowYear - i);
      }
      return parseYear;
    },

    // 자격증 파일 업로드 (최대 5개까지)
    uploadCert(event) {
      // 포맷 검사
      var allowFormat = ["pdf", "jpg", "jpeg", "png", "gif", "PDF", "JPG", "JPEG", "PNG", "GIF"];
      var checked = false;
      for (var item of allowFormat) {
        if (event.target.files[0].name.includes(item)) {
          checked = true;
          break;
        } else {
          continue;
        }
      }

      if (checked) {
        // 용량 검사
        if (event.target.files[0].size > 1048576 * 2) {
          alert("2MB 이하만 업로드 가능합니다.");
          this.$refs.certFile.value = "";
          return false;
        }
        if (this.certFiles.length >= 5) {
          alert("자격증은 최대 5개까지 업로드 가능합니다.");
          return false;
        } else {
          this.certFiles.push(event.target.files[0]);
        }
      } else {
        alert("파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.");
        this.$refs.certFile.value = "";
        return false;
      }
    },

    // 프로필 사진 업로드
    uploadProfile(event) {
      // 포맷 검사
      var allowFormat = ["jpg", "jpeg", "png", "gif", "JPG", "JPEG", "PNG", "GIF"];
      var checked = false;
      for (var item of allowFormat) {
        if (event.target.files[0].name.includes(item)) {
          checked = true;
          break;
        } else {
          continue;
        }
      }

      if (checked) {
        // 용량 검사
        if (event.target.files[0].size > 1048576 * 2) {
          alert("2MB 이하만 업로드 가능합니다.");
          this.$refs.profileFile.value = "";
          return false;
        }

        this.profileFile = event.target.files[0];
        this.$refs.profileFile.value = "";
      } else {
        alert("파일 포맷은 jpg, jpeg, png, gif형식만 가능합니다.");
        this.$refs.profileFile.value = "";
        return false;
      }
    },

    fileUpload(idxCrmStaff) {
      var params = new FormData();

      // var params = new URLSearchParams()
      params.append("idxCertFile1", -1);
      params.append("idxCertFile2", -1);
      params.append("idxCertFile3", -1);
      params.append("idxCertFile4", -1);
      params.append("idxCertFile5", -1);
      params.append("idxCrmStaff", idxCrmStaff);
      params.append("type", "");
      params.append("type1", "");
      params.append("type2", "");
      params.append("type3", "");
      params.append("type4", "");
      params.append("type5", "");

      // 기본 타입으로 추가해놓기
      params.append("certiFile1", new Blob());
      params.append("certiFile2", new Blob());
      params.append("certiFile3", new Blob());
      params.append("certiFile4", new Blob());
      params.append("certiFile5", new Blob());
      params.append("profileFile", new Blob());

      for (var [index, file] of this.certFiles.entries()) {
        params.delete("certiFile" + (index + 1));
        params.append("certiFile" + (index + 1), file);
        params.delete("type" + (index + 1));
        params.append("type" + (index + 1), "I");
      }

      if (this.profileFile !== "") {
        params.delete("profileFile");
        params.append("profileFile", this.profileFile);
        params.delete("type");
        params.append("type", "I");
      }

      this.axios
        .post("/api/v1/user/files", params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          alert("등록되었습니다.");
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        });
    },

    // 등록요청
    postUserInfo() {
      //최종 입력된 센터
      this.setIdxCrmCenter();
      // 아이디 검사
      if (!EmptyCheck(this.id, "아이디를")) {
        return false;
      } else {
        if (!IDFormatCheck(this.id)) {
          return false;
        }
      }

      // 아이디 중복체크 했는지 여부
      if (!this.isValidID) {
        alert("아이디 중복체크를 해주세요.");
        return false;
      }

      // 비밀번호 검사
      if (!EmptyCheck(this.pwd, "암호를")) {
        return false;
      } else {
        if (!PWFormatCheck(this.pwd)) {
          return false;
        }
      }

      if (!EmptyCheck(this.name, "성명을")) {
        return false;
      }

      if (!EmptyCheck(this.gender, "성별을")) {
        return false;
      }

      // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
      if (this.phone2 !== "" && this.phone3 !== "") {
        var phone = this.phone1 + "-" + this.phone2 + "-" + this.phone3;
      } else {
        var phone = "";
      }

      if (phone === "") {
        alert("핸드폰정보를 입력해주세요");
        return false;
      }

      // 이메일을 전부 입력한경우에만 이메일 포맷으로 파싱
      if (this.email1 !== "" && this.email2 !== "") {
        var email = this.email1 + "@" + this.email2;
      } else {
        var email = "";
      }

      if (email === "") {
        alert("이메일을 입력해주세요");
        return false;
      }

      // TODO : 상담사 등록요청 api 추가
      // 생년월일 데이터 파싱

      if (this.birthMM < 10) {
        this.birthTempMM = "0" + this.birthMM;
      }
      if (this.birthDD < 10) {
        this.birthTempDD = "0" + this.birthDD;
      }
      var birth = this.birthYY.toString() + "-" + this.birthTempMM.toString() + "-" + this.birthTempDD.toString();

      if (birth === "") {
        alert("생년월일을 입력해주세요");
        return false;
      }

      if (!EmptyCheck(this.education, "최종학력을")) {
        return false;
      }

      if (this.certFiles.length === 0) {
        alert("자격증 파일을 첨부하세요");
        return false;
      }

      if (!EmptyCheck(this.introduce, "한줄소개를")) {
        return false;
      }

      if (this.profileFile.length === 0) {
        alert("사진 파일을 첨부하세요");
        return false;
      }

      if (this.idxCrmCenter === -1) {
        alert("센터를 선택해주세요");
        return false;
      }

      if (this.idxCrmMetaType === -1) {
        alert("상담유형을 선택해주세요");
        return false;
      }

      // 자격증 파일 파라미터형태로 변경
      var certificateList = [];
      for (var cert of this.certFiles) {
        certificateList.push({
          certificate: cert,
        });
      }

      var vo = {
        authority: "STAFF",
        birth: birth,
        career: this.career,
        education: this.education,
        educationInfo: this.educationInfo,
        email: email,
        id: this.id,
        idxCrmCenter: this.idxCrmCenter,
        introduce: this.introduce,
        name: this.name,
        permission: "N",
        phone: phone,
        pwd: this.pwd,
        gender: this.gender,
        idxCrmMetaType: this.idxCrmMetaType,
        idxCrmMetaPro: this.idxCrmMetaPro,
      };

      if (this.btnDisabled) {
        alert("저장중입니다. 잠시만 기다려주세요.");
        return false;
      }

      this.btnDisabled = true;
      this.axios
        .post("/api/v1/user", vo)
        .then((res) => {
          if (res.data.err === 0) {
            this.fileUpload(res.data.userVO.idx);
          } else {
            alert(res.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },

    // 로그인 페이지로 이동
    moveLogin() {
      this.$router.push("/login");
    },

    /**
     * @description: 등록시 idxCrmCenter
     */
    setIdxCrmCenter() {
      const find = this.centerList.find((item) => item.name === this.centerName);
      if (find) {
        this.idxCrmCenter = find.idx;
      } else {
        this.idxCrmCenter = -1;
      }
    },
  },
};
</script>

<style scoped>
.center_text {
  text-align: center;
}
.auto_complete {
  position: absolute;
  z-index: 10;
  height: 95px;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid #dcdcdc;
}

.auto_complete .item {
  width: 384px;
  padding: 5px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}

.auto_complete .item:hover {
  background-color: #f2f2f2;
}
</style>
